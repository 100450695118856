/* eslint-disable @typescript-eslint/no-explicit-any */
import { CHAYNS_JS_URL, DIALOG_JS_URL, LANG_RES_URL } from '../constants/defaults';
import TAPPIDS from '../constants/tapp-ids';
import VERSION from '../constants/version';
import loadExternalScript from './loadExternalScript';
import { setIsScannerShown, setBoundings } from '../redux-modules/app/actions';
import { handleLoadSites } from '../redux-modules/sites/actions';
import handleSetColorMode from './handleSetColorMode';
import { renewAccessToken } from './TokenHelper';
import { remove } from './url-parameter';
import handleParameters from './handleParameters';
import { User } from '../types/user';
import logger from "../constants/logger";

type Props = {
    dispatch: (action: any) => any,
    params: {
        [key: string]: string
    },
    isMyChaynsApp: boolean,
    hideMenus: () => void,
    hideOtherContent: boolean,
    colorMode: string,
    appVersion: string,
    user: User,
    handleLoadTapp: (tappId: number) => void,
    setShowScanner: (value: boolean, callback: () => void) => void,
};

const handleAppInit = ({
    isMyChaynsApp,
    params,
    dispatch,
    hideMenus,
    hideOtherContent,
    colorMode,
    user,
    handleLoadTapp,
    setShowScanner,
    isIOS
}: Props): void => {
    console.debug(VERSION);

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration('/sw.js').then((registration) => {
            if (registration) {
                registration.unregister();
            }
        });
    }

    const loadLangRes = async () => {
        loadExternalScript(
            LANG_RES_URL,
            () => handleParameters(params, dispatch),
        );

        if (!isMyChaynsApp || activeTapp === TAPPIDS.CALENDAR || activeTapp === TAPPIDS.SMART_CLIENT || activeTapp === TAPPIDS.SITES) {
            loadExternalScript(DIALOG_JS_URL);
        }

        if (isMyChaynsApp) {
            chayns.allowRefreshScroll();
        }

        window.onReselect = (result) => {
            const {
                tappAction,
                ccUrl,
                quizUrl,
            } = result.retVal;

            if (tappAction) {
                switch (tappAction) {
                    case 'scan_qr':
                        dispatch(setIsScannerShown(true));
                        break;
                    case 'cc':
                        if (ccUrl && typeof window.qrCodeScannerChaynsCallback === 'function') {
                            window.qrCodeScannerChaynsCallback({ retVal: { qrCode: decodeURIComponent(ccUrl) } });
                        }
                        break;
                    case 'quiz':
                        if (quizUrl && typeof window.qrCodeScannerChaynsCallback === 'function') {
                            window.qrCodeScannerChaynsCallback({ retVal: { qrCode: decodeURIComponent(quizUrl) } });
                        }
                        break;
                    case 'update_sites':
                        dispatch(handleLoadSites({ isMyChaynsApp }));
                        break;
                    default:
                        break;
                }
            }
        };

        if (!isMyChaynsApp && !hideOtherContent) {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                handleSetColorMode(true, dispatch, isMyChaynsApp);
            } else if (colorMode === 'dark') {
                handleSetColorMode(false, dispatch, isMyChaynsApp);
            }
        }

        // Triggered if sites tapp is reselected in app
        if (isMyChaynsApp) {
            chayns.invokeCall(JSON.stringify({
                action: 216,
                value: {
                    callback: 'window.onReselect',
                },
            }));
        }

        // color mode changed in app
        if (isMyChaynsApp) {
            await chayns.ready;
            setTimeout(() => {
                chayns.addDesignSettingsChangeListener((result) => {
                    handleSetColorMode(result?.colorMode === 1, dispatch, isMyChaynsApp);
                });
            }, 10);
        }

        logger().info({
            message: 'Info user',
            data: {
                userId: user?.userId,
                isMyChaynsApp,
            },
            customText: isMyChaynsApp,
        });
    };

    loadExternalScript(CHAYNS_JS_URL, loadLangRes);

    dispatch(setBoundings({
        width: window.innerWidth,
        height: window.innerHeight,
    }));
    if (isIOS) {
        setInterval(() => {
            dispatch(setBoundings({
                width: window.innerWidth,
                height: window.innerHeight,
            }));
            window.dispatchEvent(new Event('resize'));
        }, 1000);
    }

    window.setShowScanner = setShowScanner;

    if (!isMyChaynsApp) {
        window.chaynsDeHideMenus = hideMenus;
    }

    const handleMediaChange = (e: { matches: boolean; }) => {
        handleSetColorMode(e.matches, dispatch, isMyChaynsApp);
    };

    if (!isMyChaynsApp && !hideOtherContent) {
        try {
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleMediaChange);
        } catch (error) {
            window.matchMedia('(prefers-color-scheme: dark)').addListener(handleMediaChange);
        }
    }

    // Add call listener
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { init } = require('./tapp/custom-tapp-communication');
    init();

    // renew token
    if (!isMyChaynsApp) {
        renewAccessToken();
    }

    require('./window-objects');

    remove('forcelogin');

    window.addEventListener('message', (event) => {
        if (event.data === 'installation_finished') {
            handleLoadTapp(TAPPIDS.SITES);
        }

        if (event.data === 'select_quiz') {
            handleLoadTapp(TAPPIDS.QUIZ);
        }

        if (event.data === 'remove_url_params') {
            window.history.replaceState({}, document.title, location.origin);
        }
    });
};

export default handleAppInit;
