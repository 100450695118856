import React, { useEffect } from 'react';
import TAPPIDS from "../../../../constants/tapp-ids";
import { SmartClientSystem } from "../../../../constants/defaults";
import ApiV5Tapp from "../ApiV5Tapp/ApiV5Tapp";
import {useDispatch, useSelector} from "react-redux";
import {loadSmartClientStore, setSelectedItem} from "../../../../redux-modules/app/actions";
import {getAppState} from "../../../../redux-modules/app/selector";
import appCall from "../../../../utils/appCall";

const SmartClientTapp = ({ loadSelectedMail, davidSiteId, mailId, mail }) => {
    const dispatch = useDispatch();
    const {activeTapp} = useSelector(getAppState);
    const infocenter = activeTapp === TAPPIDS.INFOCENTER;

    const infoCenterGoBack = () => {
        appCall(292, {
            show: true,
        }, {
            awaitResult: false,
        });
        dispatch(setSelectedItem(null));
    }

    useEffect(() => {
        dispatch(loadSmartClientStore(true));
    }, []);

    return (
        <ApiV5Tapp customData={{loadStore: false, loadSelectedMail, davidSiteId, mailId, mail, loadBoth: false, infocenter, infoCenterGoBack }} type="client-module" tappId={TAPPIDS.SMART_CLIENT} system={SmartClientSystem} />
    )
}

export default SmartClientTapp;
