import React, { useMemo, memo } from 'react';

import { useSelector } from 'react-redux';
import Tabs from './tabs/Tabs';
import TabBody from './tabs/tab-body/TabBody';
import TabHead from './tabs/tab-head/TabHead';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../../constants/height';
import { SITES } from '../../../../../constants/types';
import { getAppState } from '../../../../../redux-modules/app/selector';

const SelectView = () => {
    const app = useSelector(getAppState);
    const {
        width,
        isSmartClientAvailable,
    } = app;

    const selectElements = useMemo(() => [{
        name: 'Sites',
        type: SITES,
    }], []);

    return (
        <>
            <div
                className="wrapper"
            >
                {(width >= MOBILE_VIEW_BREAKPOINT || !isSmartClientAvailable) && (
                    <div style={{ width: '100%' }}>
                        <TabBody
                            noMargin={isSmartClientAvailable || width > MOBILE_VIEW_BREAKPOINT}
                            selectElements={selectElements}
                            index={0}
                        />
                    </div>
                )}
                {!(width >= MOBILE_VIEW_BREAKPOINT || !isSmartClientAvailable) && (
                    <Tabs
                        count={selectElements.length}
                        bodyProps={{ selectElements }}
                        TabBodyComponent={TabBody}
                        TabTextComponent={TabHead}
                    />
                )}

            </div>
            <style jsx>
                {`
                    .wrapper {
                        flex: 1 1 0%;
                        display: flex;
                        justify-content: center;
                    }
                `}
            </style>
        </>
    );
};

SelectView.displayName = 'SelectView';

export default memo(SelectView);
